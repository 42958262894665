/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

const MaineEmptyResults = function () {
  return (
    <div className="search-results__empty">
      <div className="search-results__message">
        <p className="body-copy-2">
          Sorry! Although we have thousands of learning resources on Bendable, we weren’t able to
          find what you were looking for.
        </p>
        <p className="body-copy-2">
          If you’ve done a filtered search, you might try taking off one or more of the filters. For
          example, if you’ve searched for in-person learning options only, try including online courses, as well.
        </p>
        <p className="body-copy-2">
          If you still don’t find anything of interest, you might want to try searching the
          Digital Maine Library. Just click
          {' '}
          <a href="https://library.digitalmaine.org/" target="_blank" rel="noopener">here</a>
          {' '}
          to access the site.
        </p>
      </div>
    </div>
  )
}

export default MaineEmptyResults
